@import "variables";

/* general begin */
html {
  font-size: 16px;
  @media only screen and (max-width: 990px) {
    font-size: 14px;
  }
}

h2 {
  &.large-title {font-size: 2.2rem;}
}

.portfolio-item-image-container {
  height: $portfolio_image_height;
  line-height: $portfolio_image_height;
  padding: 0 1rem;
  img {vertical-align: middle;
    @media only screen and (max-width: 767px) {
      width: 50%
    }
  }
}

p.contact_success_box {width: 100%; text-align: center;}
  /* general end */

    /* header begin */
  .navbar.navbar-default {
    a.navbar-brand img {height: 1.4rem; vertical-align: top;}
    .navbar-nav > li > a {
      font-size: 13px;
      &.purchase {text-transform: uppercase}
    }
  }
  /* header end */

  /* home-wrapper begin */
  .home-wrapper {background-image: url("../images/home/solveq-freight-forwarding-cargo.jpg")}
  /* home-wrapper end */

  /* features begin */
  ul.features-list-hero li {font-size: 1rem; clear: left;}
.features-item h3 {font-size: 1rem; color: $grey;}
  /* features end */

  /* footer begin */
  .footer p.footer-logo img {height: 2rem;}
  .footer .copyright a {text-decoration: none;}
/* footer end */
